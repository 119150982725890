#bottombar{
    background-color: #ffffff;
    position: fixed;
    bottom: 0rem;
    height: 4rem;
    width: 100%;
    text-align: center;
    padding: 1rem;
    border-top: hsla(0, 0%, 75%, 0.7) solid;

}

.bottom-bar{
    position: fixed;
    bottom: 0rem;
    height: 4rem;
    padding: 1rem;

}



.text-small-bottom{
    font-size:xx-small;
}